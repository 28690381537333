import React from "react"
import FLayout from "../../components/flayout";
import SignUpIntermediate from "../../components/signUpIntermediate";

class Page extends React.Component {
    render() {
        return (
          <div className="v-100 d-flex flex-column">
          <FLayout showFooter={false} darkHeader={true} isFullPage={true} showSingleFooter={true} headerFixed={false} noSelBackground={true} seoFields={seoFields} showAnnouncement={true} loadTimesLandingPixel={true} showB2XTypeSelection={false} isSignUpPage={true}>
            <SignUpIntermediate/>
          </FLayout>
          </div>
        )
    }
}

const seoFields = {
  description: "Start managing your investments in Stocks, Mutual Funds & other assets on web, iOS and Android devices.",
  // lang,
  // meta,
  // keywords,
  title: "Sign Up" 
}

export const query = graphql`
query cloudSignupPageQuery{
  allMarketingInfo(sort:{fields:[DisplayOrder], order:ASC}){
    edges {
      node {
        id
        Id
        Text
        DisplayOrder
      }
    }
  }
  allContentJson {
    edges {
      node {
        cloudSignupContent {
          free {
            features {
              text
              highlight
            }
            recommendedText
          }
          investor {
            features {
              text
              highlight
            }
            recommendedText
          }
          pro {
            features {
              text
              highlight
            }
            recommendedText
          }
          advisor {
            features {
              text
              highlight
            }
            recommendedText
          }
        }
      }
    }
  }
}
`

export default Page