import React from "react"

import styles from "./styles.module.css";
import {isMobile,isIOS} from 'react-device-detect';
import ChoiceCardArrow from "../../images/choice-card-arrow.inline.svg";
import GreenShape from "../../images/Green shape.png";
import { navigate } from "gatsby";

class SignUpIntermediate extends React.Component {
    constructor(props) {
        super(props);
    }

    navigateTo = (path) => {
        navigate(path);
    }

    render() {
        const { } = this.props;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
          isMobileFinal = isMobile;
          isIOSFinal = isIOS;
        }

        return (
              <>
                <div className={[styles.pageContainer].join(' ')}>
                    <div className={styles.topContainer}>
                        <div className={[styles.titleContainer,'container',"d-flex flex-column justify-content-center"].join(" ")}>
                            <div className={["row", "justify-content-md-center"].join(" ")}>
                                <div className={[styles.pageTitle,'col-md-10','d-none','d-md-block'].join(' ')}>
                                    <h2>Start tracking your investments with MProfit</h2>
                                </div>
                                <div className={[styles.pageTitle,'col-md-10','d-md-none'].join(' ')}>
                                    <h2>Start tracking your investments with MProfit</h2>
                                </div>
                            </div>
                        </div>
                        <img src={GreenShape} className={styles.greenShape}/>
                    </div>
                    <div className={[styles.contentContainer].join(" ")}>
                        <div className={["justify-content-md-center", styles.choiceCardContainer].join(" ")}>
                            <div className={[styles.choiceCard].join(' ')} onClick={() => {this.navigateTo('/sign-up/investors')}}>
                                <div className={[styles.choiceCardTitleContainer].join(' ')}>
                                    <span className={[styles.choiceCardTitle].join(' ')}>I am an Investor</span>
                                    <ChoiceCardArrow/>
                                </div>
                                <div className={styles.choiceCardContent}>
                                    I wish to track my family's investments across all asset classes in one place
                                </div>
                            </div>
                            <div className={[styles.choiceCard].join(' ')} onClick={() => {this.navigateTo('/sign-up/wealth')}}>
                                <div className={[styles.choiceCardTitleContainer].join(' ')}>
                                    <span className={[styles.choiceCardTitle].join(' ')}>I am a Wealth Professional</span>
                                    <ChoiceCardArrow/>
                                </div>
                                <div className={styles.choiceCardContent}>
                                    I wish to manage investments for my clients and provide them portfolio tracking & reporting
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </>
          )
    }
}

export default SignUpIntermediate
